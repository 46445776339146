import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Footer, StyledFooter } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Footer" title="Footer" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-footer"
    }}>{`Basic footer`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Footer>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-footer"
    }}>{`Bordered footer`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Footer bordered>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "quiet-bordered-footer"
    }}>{`Quiet bordered footer`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Footer bordered volume=\"quiet\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered volume="quiet" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transparent-footer"
    }}>{`Transparent footer`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Footer bordered transparent>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered transparent mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "secondary-footer"
    }}>{`Secondary footer`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Footer variant=\"secondary\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer variant="secondary" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-secondary-footer"
    }}>{`Bordered secondary footer`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Footer bordered variant=\"secondary\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered variant="secondary" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "quiet-bordered-secondary-footer"
    }}>{`Quiet bordered secondary footer`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Footer bordered variant=\"secondary\" volume=\"quiet\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered variant="secondary" volume="quiet" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-tertiary-footer"
    }}>{`Bordered tertiary footer`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Footer bordered variant=\"tertiary\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered variant="tertiary" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "quiet-bordered-tertiary-footer"
    }}>{`Quiet bordered tertiary footer`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Footer bordered variant=\"tertiary\" volume=\"quiet\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered variant="tertiary" volume="quiet" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h2 {...{
      "id": "pseudo-bordered-footer"
    }}>{`Pseudo-bordered footer`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Footer bordered=\"pseudo\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer bordered="pseudo" mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contrast-footer"
    }}>{`Contrast footer`}</h3>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <Footer contrast>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Footer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Footer contrast mdxType="Footer">
      <div>Hello world</div>
      <div>Hello world</div>
    </Footer>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-footer"
    }}>{`Styled footer`}</h3>
    <Playground __position={12} __code={'<StyledFooter\n  bordered\n  backgroundColor=\"#556270\"\n  borderColor=\"#393a61\"\n  height={50}\n  textColor=\"#fff\"\n>\n  <div>Hello world</div>\n  <div>Hello world</div>\n</StyledFooter>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Footer,
      StyledFooter,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StyledFooter bordered backgroundColor="#556270" borderColor="#393a61" height={50} textColor="#fff" mdxType="StyledFooter">
    <div>Hello world</div>
    <div>Hello world</div>
  </StyledFooter>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Footer} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      